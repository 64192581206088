<template>
  <div class="aboutus">
    <img src="../../assets/mobile/aboutus/banner.svg" alt style="width:100%;margin-top:55px" />
    <div class="bg" style=" margin-top: -5px;">
      <div style="width:100%;height:1px"></div>
      <div class="aboutTitle">关于我们</div>
      <div class="aboutTitle2">ABOUT&nbsp;US</div>
      <div class="line"></div>
      <div class="texts">
        <div
          class="text"
          style="letter-spacing:1px;"
        >快进时代（厦门）科技有限公司成立于2021年，总部位于福建省厦门市，是国内专业的智能零售平台。旗下产品快进商店，致力于通过自主研发的全套智能硬件+SaaS平台技术解决方案，帮助传统线下商户实现数字化、无人化升级。</div>
        <div
          class="text"
          style="margin-top:25px;letter-spacing:1px"
        >作为一家敢想敢为的科技创业公司，快进商店核心团队有着多年的门店领域相关产品研发、运营经验，曾多次获国内一线投资机构投资及政府、媒体各类奖项荣誉，是一支兼具大量实践经验和前瞻性思维的复合型人才团队。</div>
      </div>
    </div>
    <div class="bg2">
      <div class="kjTitle">
        <div style="width:100%;height:1px"></div>
        <div style="margin-top:2.3rem">
          <div class="t1">快进方向</div>
        </div>
      </div>
      <div class="t2">DIRCTION</div>
      <div class="kjfx">
        <div v-for="item in iconList" :key="item.name" style="margin-top:2.5rem;width:50%">
          <div>
            <img :src="item.url" alt style="width:3rem;height:3rem" />
          </div>
          <div style="color:#42474D;margin-top:10px;">{{item.name}}</div>
        </div>
      </div>
    </div>
     <div class="bg3">
      <div class="bg3Box">
        <div class="title">快进理念</div>
        <div class="title2">VISION</div>
        <div class="line"></div>
        <div class="text">作为零售科技企业，我们要让快进商店的产品，更有温度，更接地气，覆盖所有目标群体。同时联动上下游合作伙伴，共同探索打通智能零售全链路的发展模式。</div>
      </div>
    </div>
    <div class="bg4">
      <div class="bg4Box">
        <div class="title">快进目标</div>
        <div class="title2">OBJECTIVE</div>
        <div class="line"></div>
        <div class="text">让合作商户通过最全面、最具性价比的创新方案，真正享受到科技带来的便利性，帮助百万门店实现经营突破，挖掘商业潜能，是快进商店核心的业务目标与价值体现。</div>
      </div>
    </div>

     <div class="environment">
        <div class="centon">
            <div class="bgTitle">
                办公环境
            </div>
            <div class="img-box">
               <img src="../../assets/mobile/aboutus/descImg.png" >
            </div>
        </div>
    </div>


    <mGobalFooter></mGobalFooter>
    
  </div>
</template>
<script>
import mGobalFooter from "../../components/mobile/mGobalFooter";
export default {
   components: {
   
    mGobalFooter
  },
  data() {
    return {
      iconList: [
        {
          name: "创造可持续社会价值",
          url: require("../../assets/mobile/aboutus/icon1.svg")
        },
        {
          name: "赋能传统产业数字化转型",
          url: require("../../assets/mobile/aboutus/icon2.svg")
        },
        {
          name: "推动智能化技术和设施应用",
          url: require("../../assets/mobile/aboutus/icon3.svg")
        },

        {
          name: "为奋斗者提供舞台",
          url: require("../../assets/mobile/aboutus/icon4.svg")
        }
      ]
    };
  }
};
</script>
<style lang="scss" scoped>
.aboutus {
  font-family: opposr, sans-serif;
}
.bg {
  width: 100%;

  height: 27rem;

  background: url("../../assets/mobile/aboutus/bg1.jpg") no-repeat;
  // background-size: cover;
  position: relative;
  background-size: 100% 100%;

  .aboutTitle {
    letter-spacing: 2px;
    font-size: 1.5rem;
    font-family: opposm, sans-serif;
    font-weight: 500;
    color: #4e4d5e;
    margin-left: 11%;
    margin-top: 2.1875rem;
  }
  .aboutTitle2 {
    margin: 0.3rem 0.5rem 0.5rem;
    font-size: 0.75rem;
    font-family: opposm, sans-serif;
    font-weight: 800;
    color: #b2b2b2;

    opacity: 0.2;
    margin-left: 11%;
  }
  .line {
    width: 89%;
    height: 1px;
    background-color: #1f10e0;
    margin: 1.125rem 0;
  }
  .texts {
    margin: 0 auto 0;
    width: 78%;

    font-weight: 400;
    color: #4e4d5e;
    text-align: justify;
    line-height: 1.4375rem;
    .text {
      font-size: .875rem !important;
    }
  }
}
.bg2 {
  margin-top: 1.875rem;
  width: 100%;
  height: 23rem;

  background: url("../../assets/mobile/aboutus/bg.png") no-repeat;
  // background-size: cover;
  position: relative;
  background-size: 100% 100%;

  .t2 {
    
    width: 100%;
    text-align: center;
    font-size: 0.75rem;
    font-family: opposm, sans-serif;
    font-weight: 800;
    color: #b2b2b2;
  }
  .kjTitle {
    margin: 0 auto;
    width: 100%;

    .line {
      width: 38%;
      height: 2px;
      background-color: #b2b2b2;
    }
    .t1 {
      text-align: center;
      font-size: 1.5rem;
      font-family: opposm, sans-serif;
      font-weight: 500;
      color: #4e4d5e;
    }
  }
  .kjfx {
    text-align: center;
    width: 83%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;

    text-align: center;
    font-size: 0.75rem;

    font-weight: 500;
    color: #4e4d5e;
  }
}
.bg3 {
  margin:1.6875rem auto 1.0625rem;
  width: 90%;
  height: 15.375rem;
  background: url("../../assets/mobile/aboutus/bg2.png") no-repeat;
  // background-size: cover;
  position: relative;
  background-size: 100% 100%;
  text-align: center;
  
  letter-spacing: 1px;
  .bg3Box {
    width: 82%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .title {
    
    font-size: 1.25rem;
    font-family: opposm,sans-serif;
    font-weight: 500;
    color: #ffffff;
    letter-spacing: 1px;
  }
  .title2 {
    font-size: .625rem;
    font-family: opposm,sans-serif;
    font-weight: 800;
    color: #ffffff;

    opacity: 0.2;
  }
  .line {
    margin: .875rem auto ;
    width: 100%;
    height: 1px;
    background-color: #fff;
  }
  .text {
    letter-spacing: 1px;
    text-align: justify;
    margin: 0 auto;
    width: 100%;
    font-size:.75rem;
    
    font-weight: 400;
    color: #ffffff;
    line-height: 1.1875rem;
  }
}
.bg4 {
  margin: 0 auto 1.75rem;
 width: 90%;
  height: 15.375rem;
  background: url("../../assets/mobile/aboutus/bg3.png") no-repeat;
  // background-size: cover;
  position: relative;
  background-size: 100% 100%;
  text-align: center;
  
  letter-spacing: 1px;
  .bg4Box {
    width: 82%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .title {
    
    font-size: 1.25rem;
    font-family: opposm,sans-serif;
    font-weight: 500;
    color: #ffffff;
    letter-spacing: 1px;
  }
  .title2 {
   font-size: .625rem;
    font-family: opposm,sans-serif;
    font-weight: 800;
    color: #ffffff;

    opacity: 0.2;
  }
  .line {
   margin: .875rem auto ;
    width: 100%;
    height: 1px;
    background-color: #fff;
  }
  .line {
    margin: .875rem auto ;
    width: 100%;
    height: 1px;
    background-color: #fff;
  }
  .text {
    letter-spacing: 1px;
    text-align: justify;
    margin: 0 auto;
    width: 100%;
    font-size:.75rem;
    
    font-weight: 400;
    color: #ffffff;
    line-height: 1.1875rem;
  }
}

.environment {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 32.25rem;
  background: url("../../assets/mobile/aboutus/offBg.png") no-repeat;
  background-size:cover;
  .centon {
    margin: 0px auto;
    width: 90%;
    .bgTitle {
      margin-top: 1.75rem;
      display: flex;
      font-size: 1.5rem;
      justify-content: center;
      font-weight: 500;
      color: #3b3b72;
      letter-spacing: 2px;
      font-family: opposm,sans-serif;
    }
    .img-box {
      margin-top: 1.5rem;
      width: 100%;
      height: 24.9375rem;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}



.forRecord {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 1.3125rem;
  background: #3A1FFB;
  font-size:0.5rem;
  color: #ffffff;
}
</style>